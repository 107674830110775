var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wayfinder-select"},[_c('div',{staticClass:"wayfinder-select--selected",attrs:{"tabindex":0},on:{"click":_vm._toggleOptions,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm._toggleOptions($event)}}},[_c('div',{staticClass:"wayfinder-select--selected-conputed-value"},[(_vm.displayElementNumber)?_c('p',{staticClass:"wayfinder-select--selected-number"},[_vm._v(" "+_vm._s(_vm.elementNumber)+" ")]):_vm._e(),_c('div',{staticClass:"wayfinder-select--selected-value"},[_c('span',{staticClass:"wayfinder-select--selected-text"},[_vm._v(" "+_vm._s(_vm.selectedValue.text))]),_c('i',{class:['fa-solid', 'fa-chevron-up', 'wayfinder-select--arrow', {
          'wayfinder-select--arrow_down': _vm.isVisible
        }]})])])]),_c('ul',{class:[
    'wayfinder-select--options',
    { 'wayfinder-select--options_visible': _vm.isVisible }
  ]},_vm._l((_vm.normalizedOptions),function(option){return _c('li',{key:option.value,class:[
        'wayfinder-select--option',
        { 'wayfinder-select--option_active': _vm.selectedValue.value === option.value }
      ],attrs:{"tabindex":_vm.isVisible ? 0 : null},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.$emit('input', option.value); _vm.$emit('change', option.value); _vm._hideOptions();},"click":function($event){_vm.$emit('input', option.value); _vm.$emit('change', option.value); _vm._hideOptions();}}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }