<template>
  <div class="wayfinder-select">
    <div
      class="wayfinder-select--selected"
      @click="_toggleOptions"
      :tabindex="0"
      @keydown.enter="_toggleOptions"
    >
      <div class="wayfinder-select--selected-conputed-value">
        <p class="wayfinder-select--selected-number" v-if="displayElementNumber">
          {{ elementNumber }}
        </p>
        <div class="wayfinder-select--selected-value">
          <span class="wayfinder-select--selected-text"> {{ selectedValue.text }}</span>
          <i :class="['fa-solid', 'fa-chevron-up', 'wayfinder-select--arrow', {
            'wayfinder-select--arrow_down': isVisible
          }]"></i>
        </div>
      </div>

    </div>

    <ul :class="[
      'wayfinder-select--options',
      { 'wayfinder-select--options_visible': isVisible }
    ]">
      <li
        v-for="option in normalizedOptions"
        :key="option.value"
        :tabindex="isVisible ? 0 : null"
        @keydown.enter="$emit('input', option.value); $emit('change', option.value); _hideOptions();"
        :class="[
          'wayfinder-select--option',
          { 'wayfinder-select--option_active': selectedValue.value === option.value }
        ]"
        @click="$emit('input', option.value); $emit('change', option.value); _hideOptions();"
      >
        {{ option.text }}
      </li>
    </ul>
  </div>
</template>

<style lang="less" src="./select.less"></style>
<script>
  import lodash from "lodash";
  import isElementOf from "../../../dashboard/lib/is-element-of.js";

  export default {
    name: "wayfinder-select",
    props: {
      value: String,
      placeholder: String,
      options: {
        type: [Array, Object]
      },
      displayElementNumber: Boolean,
      displayText: Boolean
    },
    computed: {
      elementNumber(){
        return this.normalizedOptions.indexOf(this.selected) + 1;
      },
      normalizedOptions() {
        if (!Array.isArray(this.options))
          return Object.keys(this.options).map(key => {
            return {
              value: key,
              text: this.options[key]
            };
          });

        return this.options.map(option => {
          if (lodash.isObjectLike(option))
            return option;

          return { label: option, value: option };
        });
      },
      selected() {
        const selected = this.normalizedOptions.find(option => {
          if (lodash.isObjectLike(option))
            return option.value === this.value;

          return option === this.value;
        });

        return selected || this.normalizedOptions[0];
      },
      selectedValue() {
        if (this.selected === undefined)
          return this.placeholder || "";

        return this.selected;
      }
    },
    data() {
      return {
        isVisible: false
      };
    },
    methods: {
      _showOptions() {
        this.isVisible = true;
      },
      _hideOptions(event) {
        if (event && isElementOf(event.target, "wayfinder-select--selected"))
          return;

        this.isVisible = false;
      },
      _toggleOptions() {
        this.isVisible = !this.isVisible;
      }
    },
    mounted() {
      window.addEventListener("mouseup", this._hideOptions);
    },
    beforeDestroy() {
      window.removeEventListener("mouseup", this._hideOptions);
    },
    screenPending() {
      this.isVisible = false;
    }
  };
</script>
