<template>
  <div
    :class="[
      'wayfinder-map',
      {
        'wayfinder-map_dragging-in-progress': !ready || drag,
        'wayfinder-map_jump-animation': jumpAnimation,
        'wayfinder-map_here-marker-jump-animation': hereMarkerJumpAnimation
      }
    ]"
    @mousedown="_dragStart"
    @touchstart="_dragStart"
  >
    <div class="wayfinder-map--map-image-wrapper" :style="mapStyles">
      <img ref="scaledImage" draggable="false" class="wayfinder-map--map-image" :src="mapImage"
           :alt="$withLocale(this.selectedMap.label) + ' ' + $l10n('map')"
      />
    </div>
    <div class="wayfinder-map--map-markers-wrapper" :style="mapStyles">
      <div class="wayfinder-map--map-markers">
        <template v-for="legendElement in selectedLegendElements">
          <template v-for="(placement, placementIndex) in legendElement.markers">
            <div
              v-if="legendElement.type === 'point'"
              :key="`${ legendElement.id }-${ placementIndex }`"
            >
              <i
                class="wayfinder-map--map-marker wayfinder-map--location-dot fa-light fa-location-dot"
                :style="{
                  color: legendElement.color,
                  top: placement.top,
                  left: placement.left,
                  fontSize: `${ markersFontSize }px`
                }"
              ></i>
            </div>
            <svg
              v-else-if="legendElement.type === 'area'"
              :key="`${ legendElement.id }-${ placementIndex }`"
              class="wayfinder-map--map-marker wayfinder-map--map-area"
              :style="{
                color: legendElement.color,
                top: placement.top,
                left: placement.left,
                width: placement.width,
                height: placement.height,
                transform: `scale(${ $easyscreenCanvasOrientation.scale })`
              }"
            >
              <polygon
                :points="placement.points.map(point => `${ point.x } ${ point.y }`).join(', ')"
                :style="{ fill: legendElement.color }"
              ></polygon>
            </svg>

            <i
              v-else-if="legendElement.type === 'here-marker'"
              :key="`${ legendElement.id }-${ placementIndex }`"
              class="wayfinder-map--map-marker wayfinder-map--here-icon fa-light fa-crosshairs"
              :style="{
                top: placement.top,
                left: placement.left,
                fontSize: `${ markersFontSize }px`
              }"
            >

            </i>

            <div
              :key="`${ legendElement.id }-${ placementIndex }`"
              v-if="legendElement.serviceButtonId"
            >
              <div
                v-if="theme === 'contrast-dark' || theme === 'contrast-light'"
                class="wayfinder-map--map-marker wayfinder-map--map-marker_point"
                :style="{
                  top: placement.top,
                  left: placement.left,
                  width: placement.width,
                  height: placement.height,
                  transform: `scale(${ $easyscreenCanvasOrientation.scale })`,
                  '--base-size': `${ markersFontSize }px`
                }"
              >
                <p class="wayfinder-map--map-marker-title">{{ $withLocale( legendElement.label) }}</p>
                <span
                  :class="['wayfinder-map--map-marker-title-point', {
                    'wayfinder-map--map-marker-title-point_bordered': !legendElement.color || legendElement.color === '#ffffff'
                  }]"
                  :style="{ background: legendElement.color || '#000000' }"
                ></span>
              </div>
              <i
                v-else
                :class="[
                  'wayfinder-map--map-marker',
                  'wayfinder-map--service-icon',
                  legendElement.class
                ]"
                :style="{
                  top: placement.top,
                  left: placement.left,
                  fontSize: `${ serviceButtonsFontSize }px`
                }"
              ></i>
            </div>
          </template>
        </template>
      </div>
    </div>
    <img
      ref="originalImage"
      v-onready="_updateDefaultScaleAndOffset"
      class="wayfinder-map--map-original-image"
      :src="mapImage"
      :alt="$withLocale(this.selectedMap.label) + ' ' + $l10n('map')"
    />
  </div>
</template>

<style src="./map.less" lang="less"></style>
<script>
  import { mapState } from "vuex";
  import lodash from "lodash";
  import asyncTimeout from "../../../dashboard/lib/async-timeout.js";
  import orientationMixin from "../mixins/orientation.js";
  import dragAndDropMixin from "../mixins/drag-and-drop.js";

  export default {
    name: "wayfinder-map",
    mixins: [orientationMixin, dragAndDropMixin],
    props: {
      map: Object,
      theme: {
        type: String,
        default: ""
      }
    },
    watch: {
      selectedLegendElements() {
        this._playJumpAnimation();
      },
      sidebarOpened() {
        this._updateDefaultScaleAndOffsetSmooth();
      }
    },
    computed: {
      ...mapState({
        sidebarOpened: state => state.sidebarOpened
      }),
      selectedMap() {
        return this.$store.getters.selectedMap;
      },
      selectedLegendElements() {
        if (this.selectedMap.id === this.map.id)
          return this.$store.getters.selectedLegendElements;

        return [];
      },
      mapImage() {
        return this.map ? this.map.img : "/images/no-map-image.png";
      },
      mapStyles() {
        return {
          left: `${ this.offsetX }px`,
          top: `${ this.offsetY }px`,
          transform: `scale(${ this.scale })`,
          width: `${ this.defaultImageWidth }px`,
          height: `${ this.defaultImageHeight }px`
        };
      },
      markersFontSize() {
        return this.defaultImageWidth / 20;
      },
      serviceButtonsFontSize() {
        return this.defaultImageWidth / 25;
      },
      areaPointSize() {
        return this.defaultImageWidth / 50;
      },
      basePolygonOffset() {
        return this.defaultImageWidth / 25;
      },
      defaultPolygonWidth() {
        return this.defaultImageWidth / 10;
      },
      defaultPolygonHeight() {
        return this.defaultImageWidth / 10;
      }
    },
    data() {
      return {
        defaultImageWidth: 0,
        defaultImageHeight: 0,
        defaultScale: 1,
        defaultOffsetX: 0,
        defaultOffsetY: 0,
        isDefaultPosition: true,

        jumpAnimation: false,
        hereMarkerJumpAnimation: false,
        ready: false,
        drag: false,
        scale: 1,
        offsetX: 0,
        offsetY: 0,
        startClientX: 0,
        startClientY: 0,
        minOffsetX: 0,
        maxOffsetX: 0,
        minOffsetY: 0,
        maxOffsetY: 0
      };
    },
    methods: {
      _mapMoveStart() {
        this.drag = true;
        this.lastOffsetX = this.offsetX;
        this.lastOffsetY = this.offsetY;
      },
      _mapMoveTick(event) {
        if (this.isDefaultPosition == true) {
          this.isDefaultPosition = false;
          this.$emit("position-changed", this);
        }

        this.offsetX = lodash.clamp(this.lastOffsetX + event.deltaX, this.minOffsetX, this.maxOffsetX);
        this.offsetY = lodash.clamp(this.lastOffsetY + event.deltaY, this.minOffsetY, this.maxOffsetY);
      },
      _mapMoveEnd() {
        this.drag = false;
      },
      async _updateDefaultScaleAndOffset(options) {
        options = {
          forceReset: false,
          manualReady: false,
          ...(options || {})
        };

        if (!this.$refs.originalImage)
          return;

        const viewportBCR = this.$el.getBoundingClientRect();
        const originalImageBCR = this.$refs.originalImage.getBoundingClientRect();
        const appScale = this.$easyscreenCanvasOrientation.scale;

        this.defaultImageWidth = originalImageBCR.width;
        this.defaultImageHeight = originalImageBCR.height;
        this.defaultScale = Math.min(viewportBCR.width / originalImageBCR.width, viewportBCR.height / originalImageBCR.height) / appScale;
        this.defaultOffsetX = (viewportBCR.width / appScale - originalImageBCR.width) / 2;
        this.defaultOffsetY = (viewportBCR.height / appScale - originalImageBCR.height) / 2;

        this.minOffsetX = -originalImageBCR.width / 2;
        this.maxOffsetX = viewportBCR.width / appScale - originalImageBCR.width / 2;

        this.minOffsetY = -originalImageBCR.height / 2;
        this.maxOffsetY = viewportBCR.height / appScale - originalImageBCR.height / 2;

        if (this.isDefaultPosition || options.forceReset === true) {
          this._resetMapPositionAndScale();
        }

        if (options.manualReady !== true) {
          await asyncTimeout(300);
          this.ready = true;
        }
      },
      async _updateDefaultScaleAndOffsetForce() {
        this.ready = false;
        await this.$nextTick();
        this._updateDefaultScaleAndOffset({ manualReady: true, forceReset: true });
        await asyncTimeout(100);
        this.ready = true;
      },
      async _updateDefaultScaleAndOffsetSmooth() {
        const isDefaultPositionAndScale = this.isDefaultPosition && this.scale === this.defaultScale;

        if (!isDefaultPositionAndScale) {
          /* TODO: do the smooth transition. */
          this._resetMapPositionAndScale();
        }

        this.ready = false;

        if (this._updateDefaultScaleAndOffsetSmoothTimeout) {
          clearTimeout(this._updateDefaultScaleAndOffsetSmoothTimeout._timeout);
          clearInterval(this._updateDefaultScaleAndOffsetSmoothInterval);
        }

        this._updateDefaultScaleAndOffsetSmoothInterval = setInterval(() => {
          this._updateDefaultScaleAndOffset({ manualReady: true });
        }, 16);

        this._updateDefaultScaleAndOffsetSmoothTimeout = asyncTimeout(500);

        await this._updateDefaultScaleAndOffsetSmoothTimeout;
        clearInterval(this._updateDefaultScaleAndOffsetSmoothInterval);
        this._updateDefaultScaleAndOffsetSmoothInterval = null;
        this._updateDefaultScaleAndOffsetSmoothTimeout = null;
        this.ready = true;
      },
      _resetMapPositionAndScale() {
        this.scale = this.defaultScale;
        this.offsetX = this.defaultOffsetX;
        this.offsetY = this.defaultOffsetY;

        this.isDefaultPosition = true;
        this.$emit("position-changed", this);
      },
      async _playJumpAnimation() {
        if (this._jumpAnimationTimeout) {
          clearTimeout(this._jumpAnimationTimeout._timeout);
        }

        this.jumpAnimation = true;
        const hereMarkerSelected = this.selectedLegendElements.some(marker => {
          return marker.type === "here-marker" && marker._isActive;
        });
        if (hereMarkerSelected) {
          this.hereMarkerJumpAnimation = true;
        }

        this._jumpAnimationTimeout = asyncTimeout(1500);

        await this._jumpAnimationTimeout;
        this._jumpAnimationTimeout = null;
        this.jumpAnimation = false;
        this.hereMarkerJumpAnimation = false;
      }
    },
    mounted() {
      this._playJumpAnimation();
      this.$on("orientation-changed", this._updateDefaultScaleAndOffsetForce);
      this.$on("dnd-start", this._mapMoveStart);
      this.$on("dnd-move", this._mapMoveTick);
      this.$on("dnd-end", this._mapMoveEnd);
    },
    beforeDestroy() {
      this.$off("orientation-changed", this._updateDefaultScaleAndOffsetForce);
      this.$off("dnd-start", this._mapMoveStart);
      this.$off("dnd-move", this._mapMoveTick);
      this.$off("dnd-end", this._mapMoveEnd);
    },
    screenPending() {
      this._updateDefaultScaleAndOffsetSmooth();
    }
  };
</script>
