<template>
  <div
    :class="['wayfinder-layout-contrast--wrapper', {
      'wayfinder-layout-contrast--wrapper-light-theme': isLightMode
    }]"
  >
    <wayfinder-button-group class="wayfinder-layout-contrast--header-buttons">
      <wayfinder-button
        size="big"
        class="wayfinder-layout-contrast--font-scale"
        @click.native="_changeScale()"
      >
        <div class="wayfinder-layout-contrast--font-scale-indicator">
          <span class="wayfinder-layout-contrast--font-scaled">A</span>
          <span class="wayfinder-layout-contrast--font-default">a</span>
        </div>
      </wayfinder-button>
      <wayfinder-button
        v-if="$accessibility.enableModeToggle === true"
        class="wayfinder-layout-contrast--theme-toggle"
        size="big"
        @click.native="_setTheme(isLightMode ? 'contrast-dark' : 'contrast-light')"
        :aria-label-value="$l10n('toggle theme')"
        :title="$l10n('toggle theme')"
      >
        <i
          :class="[
            { 'fa-light fa-brightness': !isLightMode },
            { 'fa-solid fa-moon': isLightMode }
          ]"
        ></i>
      </wayfinder-button>
      <wayfinder-button
        :aria-label-value="$l10n(sidebarOpened ? 'menu close' : 'menu open')"
        :title="$l10n(sidebarOpened ? 'menu close' : 'menu open')"
        class="wayfinder-layout-contrast--sidebar-handle"
        corners="pill"
        size="big"
        data-focus-frame-follow="500"
        @click.native="_toggleSidebar"
      >
        <i
          :class="[
            'fa-light',
            {
              'fa-times': sidebarOpened,
              'fa-bars': !sidebarOpened
            }
          ]"
        ></i>
      </wayfinder-button>
    </wayfinder-button-group>
    <div :class="['wayfinder-layout-contrast', 'wayfinder-layout--content', {
      'wayfinder-layout-contrast--light-theme': isLightMode
    }]">
      <div class="wayfinder-layout-contrast--header">
        <div v-if="logoImage" class="wayfinder-layout-contrast--logo">
          <img
            class="wayfinder-layout-contrast--logo-image"
            :src="logoImage"
            :alt="$l10n('logo')"
          />
        </div>
        <div class="wayfinder-layout-contrast--text">
          <h1 :class="['wayfinder-layout-contrast--title', {
            'wayfinder-layout-contrast--title_big': !logoImage
          }]">
            {{ title }}
          </h1>

          <h5 v-if="view.branch" class="wayfinder-layout-contrast--sub-title">
            {{ view.branch.name }}
          </h5>
        </div>
      </div>

      <blank-modal
        ref="informationModal"
        class="wayfinder-layout-contrast--modal-info"
        @before-close="isInfoVisible = false"
      >
        <div class="wayfinder-modal--content">
          <h1 ref="informationTitleRef" class="wayfinder-layout-contrast--modal-title">
            {{ informationTitle }}
          </h1>
          <scrollable
            class="wayfinder-layout-contrast--modal-text"
            maxHeight="100%"
            smoothEdgeColor="#cccccc"
          >
            <div class="ql-snow">
              <div class="ql-editor" v-html="information"></div>
            </div>
          </scrollable>

          <wayfinder-button
            class="wayfinder-layout-contrast--close-button wayfinder-modal--close-button wayfinder-modal--close-button_top-right"
            color="white"
            corners="square"
            @click.native="() => $refs.informationModal.hide()"
          >
            <span>{{ $l10n("Close") }}</span>
          </wayfinder-button>

          <wayfinder-button
            class="wayfinder-layout-contrast--close-button wayfinder-modal--close-button wayfinder-modal--close-button_bottom-right"
            color="white"
            corners="square"
            @click.native="() => $refs.informationModal.hide()"
          >
            <span>{{ $l10n("Close") }}</span>
          </wayfinder-button>
        </div>
      </blank-modal>

      <blank-modal
        ref="descriptionModal"
        class="wayfinder-layout-contrast--modal-description wayfinder-layout-contrast--modal-info"
      >
        <div class="wayfinder-modal--content">
          <h1 ref="informationTitleRef" class="wayfinder-layout-contrast--modal-title">
            {{ descriptionTitle }}
          </h1>
          <scrollable
            class="wayfinder-layout-contrast--modal-text"
            maxHeight="100%"
            smoothEdgeColor="#cccccc"
          >
            <div class="ql-snow">
              <div class="ql-editor" v-html="description"></div>
            </div>
          </scrollable>

          <wayfinder-button
            class="wayfinder-layout-contrast--close-button wayfinder-modal--close-button wayfinder-modal--close-button_top-right"
            color="white"
            corners="square"
            @click.native="() => $refs.descriptionModal.hide()"
          >
            <span>{{ $l10n("Close") }}</span>
          </wayfinder-button>

          <wayfinder-button
            class="wayfinder-layout-contrast--close-button wayfinder-modal--close-button wayfinder-modal--close-button_bottom-right"
            color="white"
            corners="square"
            @click.native="() => $refs.descriptionModal.hide()"
          >
            <span>{{ $l10n("Close") }}</span>
          </wayfinder-button>
        </div>
      </blank-modal>

      <div class="wayfinder-layout-contrast--desktop-floating-block">
        <div class="wayfinder-layout-contrast--scroll-tape">
          <div
            :style="mapListStyles"
            class="wayfinder-layout-contrast--maps-wrapper"
          >
            <wayfinder-map
              v-for="map in reversedMaps"
              :key="map.id"
              ref="maps"
              :map="map"
              :theme="view.theme"
              class="wayfinder-map-list--map"
              @position-changed="_updateMapDefaultPosition(map._index, $event)"
            />
          </div>
        </div>

        <div class="wayfinder-layout-contrast--map-buttons-wrapper">
          <wayfinder-select
            :value="selectedMap.id"
            :options="branchOptions"
            @change="selectMap($event)"
            display-element-number
          />
          <wayfinder-button-group
            v-if="$accessibility.zoomDisabled !== true"
            class="wayfinder-layout-contrast--map-controls"
            gap-side="start"
          >
            <wayfinder-button
              :aria-label-value="$l10n('zoom restore')"
              :class="[
                'wayfinder-layout-contrast--map-control',
                { 'wayfinder-map-list--map-control_hidden': selectedMapInDefaultPostion }
              ]"
              :tabindex-value="selectedMapInDefaultPostion ? - 1 : 0"
              :title="$l10n('zoom restore')"
              color="black"
              modificator="only-text"
              corners="circle"
              @click.native="_resetMapPositionAndScale"
            >
              <i class="fa-light fa-arrows-rotate wayfinder-layout-contrast--control-icon"></i>
            </wayfinder-button>
            <wayfinder-button
              :aria-label-value="$l10n('zoom down')"
              :title="$l10n('zoom down')"
              class="wayfinder-layout-contrast--map-control"
              color="black"
              corners="circle"
              modificator="only-text"
              @click.native="_zoomIn"
            >
              <i class="fa-regular fa-magnifying-glass-plus wayfinder-layout-contrast--control-icon"></i>
            </wayfinder-button>
            <wayfinder-button
              :aria-label-value="$l10n('zoom up')"
              :title="$l10n('zoom up')"
              class="wayfinder-layout-contrast--map-control"
              color="black"
              corners="circle"
              modificator="only-text"
              @click.native="_zoomOut"
            >
              <i class="fa-regular fa-magnifying-glass-minus wayfinder-layout-contrast--control-icon"></i>
            </wayfinder-button>
          </wayfinder-button-group>
        </div>
      </div>

      <div :class="['wayfinder-layout-contrast--sidebar-content', {
        'wayfinder-layout-contrast--sidebar-content_close': !sidebarOpened
      }]">
        <div class="wayfinder-layout-contrast--view-settings">
          <wayfinder-button
            v-if="selectedLegend.length > 1"
            class="wayfinder-layout-contrast--come-back"
            color="black"
            @click.native="back"
            modificator="only-text"
          >
            <i
              :class="['fa-regular', 'fa-arrow-left', 'wayfinder-layout-contrast--come-back-icon']"
            ></i>
            {{ $l10n('Return to start') }}
          </wayfinder-button>

          <div class="wayfinder-layout-contrast--information-language-wrapper">
            <wayfinder-button
              class="wayfinder-layout-contrast--toggle-list-button"
              color="black"
              @click.native="openInfo"
              modificator="only-text"
            >
              <i :class="['fa-light', 'fa-eye', 'wayfinder-layout-contrast--toggle-list-button-icon', {
                'fa-eye': isInfoVisible,
                'fa-eye-slash': !isInfoVisible
              }]"></i>
              {{ isInfoVisible ? $l10n("Hide instruction") : $l10n("Show instructions") }}
            </wayfinder-button>

            <wayfinder-button-group
              class="wayfinder-layout-contrast--language-controls"
              gap="small"
            >
              <wayfinder-button
                v-for="option in availableLocales"
                :key="`${ option.label }-${ option.value }`"
                :active="selectedLanguage === option.value"
                class="wayfinder-layout-contrast--language-control"
                color="black"
                @click.native="_selectLocale(option.value)"
              >
                {{ option.label }}
              </wayfinder-button>
            </wayfinder-button-group>
          </div>
          <select
            :value="selectedLanguage"
            @change="(event) => _selectLocale(event.target.value)"
            class="wayfinder-layout-contrast--mobile-language-controls"
          >
            <option
              v-for="option in availableLocales"
              :key="option.value"
              :value="option.value"
              class="wayfinder-layout-contrast--option"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
        <div :class="['wayfinder-layout-contrast--user-action-area', {
          'wayfinder-layout-contrast--user-action-area_with-separator': !isScrollAvalialable
        }]">
          <div class="wayfinder-layout-contrast--contrast-buttons">
            <scrollable
              class="wayfinder-layout-contrast--list-scrollable wayfinder-layout-contrast--left-side"
              maxHeight="100%"
              @scroll-avaiable="isScrollAvalialable = $event"
            >
              <div class="wayfinder-layout-contrast--headings">
                <ul class="wayfinder-layout-contrast--legend-list">
                  <li class="wayfinder-layout-contrast--legend-item">
                    <wayfinder-button
                      @click.native="openServiceButton"
                      class="wayfinder-layout-contrast--button wayfinder-layout-contrast--head wayfinder-layout-contrast--button-marker-heading"
                      :active="isServiseButtonOpen"
                    >
                      {{ $l10n("Services") }}
                    </wayfinder-button>
                  </li>
                  <li
                    v-for="(legendItem, legendIndex) in selectedMap.legend"
                    class="wayfinder-layout-contrast--legend-item"
                    :key="legendItem.label + legendIndex"
                  >
                    <wayfinder-button
                      class="wayfinder-layout-contrast--button wayfinder-layout-contrast--button-marker-heading"
                      :active="selectedLegend[0] && selectedLegend[0]._parent.id === legendItem.id"
                      @click.native="_showChildElements(legendItem, { isRootElement: true })"
                    >
                      {{ $withLocale(legendItem.label) }}
                    </wayfinder-button>
                  </li>
                </ul>
              </div>
            </scrollable>
          </div>

          <div
            :class="['wayfinder-layout-contrast--markers wayfinder-layout-contrast--list-scrollable', {
              'wayfinder-layout-contrast--markers_legend': selectedLegend.length !== 0
            }]">
            <scrollable
              v-if="displayHint"
              class="wayfinder-layout-contrast--list-scrollable"
              maxHeight="100%"
            >
              <div class="wayfinder-layout-contrast--hint">
                <h3
                  class="wayfinder-layout-contrast--hint-title"
                  v-html="$l10n('Select from menu <br/> to view the data on the map')"
                ></h3>

                <p
                  class="wayfinder-layout-contrast--hint-subtitle"
                  v-html="$l10n('Below the services you can find e.g. WCt, lifts, <br/> printers and reserved spaces')"
                ></p>
              </div>
            </scrollable>
            <scrollable
              v-if="isServiseButtonOpen"
              visibleScrollBar
              class="wayfinder-layout-contrast--list-scrollable_service-butons wayfinder-layout-contrast--list-scrollable"
              maxHeight="100%"
            >
              <div class="wayfinder-layout-contrast--service-buttons">
                <wayfinder-button
                  v-for="serviceButton in serviceButtons"
                  :key="serviceButton.id"
                  :id="`service-button-${ serviceButton.id }`"
                  :data-marker-id="serviceButton.id"
                  :title="$withLocale(serviceButton.label)"
                  :active="isActive(serviceButton.id)"
                  :class="['wayfinder-layout-contrast--service-button', 'wayfinder-layout-contrast--button', {
                    'wayfinder-layout-contrast--service-button_preselected': selectedLegendElements.length > 1
                  }]"
                  @click.native="_selectLegendElements(serviceButton)"
                >
                  {{ $withLocale(serviceButton.label) }}
                </wayfinder-button>
              </div>
            </scrollable>

            <div
              :key="legendIndex"
              v-for="(legend, legendIndex) in selectedLegend"
              class="wayfinder-layout-contrast--selected-legend"
              :style="{ top: (selectedLegend.length - 1 - legendIndex) * 100 + '%' }"
            >
              <div v-if="isDescriptionElement(legend._parent)">
                <div class="wayfinder-layout-contrast--information">
                  <scrollable
                    v-if="legend._parent._selectedPoints !== 0"
                    class="wayfinder-layout-contrast--list-scrollable"
                  >
                    <h3
                      class="wayfinder-layout-contrast--description"
                      v-html="$withLocale(legend._parent.description)"
                    ></h3>

                    <div class="wayfinder-layout-contrast--selected-legend-scrollable wayfinder-layout-contrast--related-markers">
                      <li
                        v-for="point in legend._parent._selectedPoints"
                        :key="point.id"
                        class="wayfinder-layout-contrast--selected-legend-item"
                        @click="selectElement(point)"
                      >
                        {{ $withLocale(point.label) }}
                      </li>
                    </div>
                  </scrollable>
                </div>
              </div>

              <div v-else>
                <p class="wayfinder-layout-contrast--selected-legend-label">
                  {{ $withLocale(selectedLegend[0]._parent.label) }}
                  <wayfinder-button
                    class="wayfinder-layout-contrast--button wayfinder-layout-contrast--description-button"
                    v-if="legend._parent.description"
                    @click.native="openDescription(legend._parent)"
                  >
                    <i class="fa-light fa-circle-info wayfinder-layout-contrast--description-icon"></i>
                  </wayfinder-button>
                </p>

                <scrollable
                  v-if="selectedLegend.length !== 0"
                  class="wayfinder-layout-contrast--list-scrollable"
                >
                  <div class="wayfinder-layout-contrast--selected-legend-scrollable">
                    <wayfinder-button
                      v-for="legendElement in legend"
                      :key="legendElement.id"
                      :active="isActive(legendElement.id)"
                      class="wayfinder-layout-contrast--selected-legend-item wayfinder-layout-contrast--button"
                      @click.native="_showChildElements(legendElement)"
                    >
                      <li>
                        {{ $withLocale(legendElement.label) }}
                      </li>
                    </wayfinder-button>
                  </div>
                </scrollable>
              </div>
            </div>
          </div>
        </div>

        <div class="wayfinder-layout-contrast--mobile-action-area">
          <scrollable
            ref="scrollable"
            class=" wayfinder-layout-contrast--list-scrollable wayfinder-layout--sidebar-scrollable"
            maxHeight="100%"
          >
            <wayfinder-legend @open-description="(element) => openDescription(element)"/>
          </scrollable>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./layout-content-contrast.less" lang="less"></style>

<script>
  import { mapState, mapActions } from "vuex";
  import lodash from "lodash";
  import WayfinderSelect from "../../select/select.vue";
  import WayfinderMapList from "../../map/map-list.vue";
  import LanguageSeelct from "../../language-select/language-select.vue";
  import Scrollable from "../../scrollable/scrollable.vue";
  import BlankModal from "../../modal/blank.vue";
  import WayfinderLegend from "../../legend/legend.vue";

  export default {
    name: "layout-content-contrast",
    mixins: [WayfinderMapList, LanguageSeelct],

    data(){
      return {
        isInfoVisible: false,
        isServiseButtonOpen: false,
        isScrollAvalialable: false,
        selectedLegend: [],
        displayHint: true,
        descriptionElement: {}
      };
    },
    methods: {
      ...mapActions({
        _selectLocale: "selectLocale",
        _selectLegendElements: "selectLegendElements",
        _toggleLegendElement: "toggleLegendElement",
        _setTheme: "setTheme",
        _hideSidebar: "hideSidebar",
        _toggleSidebar: "toggleSidebar",
        _showSidebar: "showSidebar",
        _changeScale: "changeScale",
        _resetScale: "resetScale"
      }),
      setDefaultThemeMode() {
        if (this.view.theme === "default") {
          this._setTheme(this.$globalTheme);
        } else {
          this._setTheme(this.view.theme);
        }
      },
      _showChildElements(element, options) {
        options = {
          isRootElement: false,
          ...(options || {})
        };
        this.displayHint = false;
        this.isServiseButtonOpen = false;

        function recursiveMarkersBuilder(legend, selectedMarkers, _found) {
          const isRootCall = _found == null;
          _found = _found || [];

          legend.forEach( marker => {
            if (selectedMarkers.includes(marker.id)) {
              _found.push( marker );
            }

            if (marker.sub && marker.sub !== 0) {
              recursiveMarkersBuilder(marker.sub, selectedMarkers, _found);
            }
          } );

          if (isRootCall) {
            _found.sort( (a, b) => selectedMarkers.indexOf(a.id) - selectedMarkers.indexOf(b.id) );
          }
          return _found;
        }

        element.sub._parent = element;
        if (options.isRootElement) {
          this.selectedLegend = [];

          if (!element.sub || element.sub.length === 0) {
            this.selectedLegend = this.selectedLegend.concat([element.sub]);
          }
        }

        if (element.selectedPoints && element.selectedPoints !== 0) {
          element._selectedPoints = recursiveMarkersBuilder(this.selectedMap.legend, element.selectedPoints);
          this._selectLegendElements(element);
        }

        if (element.sub.length !== 0) {
          this._selectLegendElements(element.sub._parent);
          this.selectedLegend = this.selectedLegend.concat([element.sub]);
        }

        if (element.sub.length === 0){
          this._selectLegendElements(element);
        }
      },
      isActive(id) {
        return this.$store.getters.selectedLegendElements.some(element => element.id === id);
      },
      isDescriptionElement(element) {
        return !element.sub || element.sub.length === 0;
      },
      selectMap(value) {
        this.selectedLegend = [];
        this.__selectMap( value );
      },
      back() {
        this.selectedLegend = this.selectedLegend.slice(0, this.selectedLegend.length - 1);

        if (this.selectedLegend.length !== 0) {
          this._selectLegendElements(this.selectedLegend[this.selectedLegend.length - 1]._parent);
        }
      },
      openServiceButton() {
        this.displayHint = false;
        this.isServiseButtonOpen = true;
        this.selectedLegend = [];
        this._selectLegendElements(this.$store.getters.selectedMap.icons);
      },
      openInfo() {
        this.isInfoVisible = true;

        this.$refs.informationModal.show();
      },

      openDescription(descriptionElement){
        this.descriptionElement = descriptionElement;

        this.$refs.descriptionModal.show();
      },
      selectElement(element){
        this._selectLegendElements(element);
        this._hideSidebar();
      }
    },
    computed: {
      ...mapState({
        theme: state => state.theme,
        localesNames: state => state.view.localesNames,
        locales: state => state.view.locales,
        serviceButtons: state => {
          if (!state.view.view)
            return [];

          return state.view.view.serviceButtons;
        },
        sidebarOpened: state => state.sidebarOpened
      }),
      isLightMode(){
        return this.theme === "contrast-light";
      },
      availableLocales(){
        return this.locales.map((localeCode) => {
          return {
            value: localeCode,
            label: this.localesNames[localeCode] || localeCode
          };
        });
      },
      information() {
        return lodash.get(this.view, `information[${ this.selectedLanguage }]`);
      },
      informationTitle() {
        return lodash.get(this.view, `informationTitle[${ this.selectedLanguage }]`);
      },

      description() {
        return lodash.get(this.descriptionElement, `description[${ this.selectedLanguage }]`);
      },
      descriptionTitle() {
        return lodash.get(this.descriptionElement, `label[${ this.selectedLanguage }]`);
      },

      legend(){
        return this.view;
      },
      selectedLanguage: {
        get() {
          return this.$store.getters.selectedLocale;
        }
      },
      view() {
        return this.$store.getters.filteredView;
      },
      logoImage() {
        return this.$store.getters.filteredView.logoImage;
      },
      title() {
        return this.$withLocale(this.view.title);
      },
      branchOptions(){
        return this.view.maps.map( (map) => {
          return {
            value: map.id,
            text: this.$withLocale(map.label)
          };
        });
      },
      selectedMap(){
        return this.$store.getters.selectedMap;
      },
      selectedLegendElements() {
        return this.$store.getters.selectedLegendElements;
      }
    },
    mounted() {
      if (this.theme === "")
        this.setDefaultThemeMode();
    },
    components: {
      WayfinderSelect,
      Scrollable,
      BlankModal,
      WayfinderLegend
    },
    screenPending() {
      this.selectedLegend = [];
      this.isListVisible = true;
      this.branch = null;
      this.displayHint = true;
      this.isServiseButtonOpen = false;
      this.setDefaultThemeMode();
      this._resetScale();
    }
  };
</script>
